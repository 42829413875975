.auth-page {
  background: url('/oya-background-image.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
}

.oya-title {
  /* font-family: 'Poppins'; */
  color: #ffffff;
}

.signin-card {
  background-color: rgba(255, 255, 255, 0.96);
  border-radius: 10px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.form-field {
  margin-bottom: 20px;
}

.forgot-password {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.forgot-password a {
  color: #ca020e; /* Set the color to orange */
  text-decoration: none; /* Remove the default underline */
}

.forgot-password a:hover {
  text-decoration: underline; /* Add underline on hover if desired */
}

.phone > input {
  outline: none;
}

.button {
  /* width: 124px; */
  height: 44px;
  background-color: #ca020e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  margin-top: 90px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.button-container {
  text-align: center;
}

.button:hover {
  /* Hover styles */
  cursor: pointer;
  background-color: #ee2a37;
}

.dashboard-sidebar-button {
  flex-direction: row;

  height: 84px;

  background: #ffefef;
  border-radius: 10px;
  border: none;
  color: #ca020e;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  margin-top: 20px;
  width: 220px;
}

/* .phone input {
  height: 55px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
} */

/* .rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  width: 282px;
} */

/* picked date align */
/* .rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 67px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: #575757;
  border: 1px solid #e5e5ea;
  padding-left: 44px;
} */
/* calander align */
/* .rs-picker-toggle-caret {
  display: inline-block;
  margin-right: 40px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
  color: #8e8e93;
} */

/* ok button style */
/* .rs-picker-toolbar-right-btn-ok {
  text-align: center;
  cursor: pointer;
  outline: 0;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 30px;
  color: #fff;
  background-color: #ca020e;
  width: 100px;
} */

/* range style */
/* .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
} */

.coupon-button {
  background-color: #ffe793;
  height: 45px;
  color: #cba300;
  background: #ffe793;
  border-radius: 5px;
  padding: 2px 10px;
  font-weight: 700;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 5px;
}

.dashboard-sidebar-list-item {
  margin-top: 2px;
}

.dashboard-sidebar-list-item-text {
  margin: 2px;
}

.dashboard-sidebar-list-item-icon {
  padding-left: 20px;
  padding-left: 20px;
}

/* .rs-picker-menu {
  left: 0 !important;
  visibility: hidden;
} */

.coupon-qr {
  color: #cba300;
}

/* prosidebar active style start */
.active {
  background-color: #7c000008 !important;
  color: #000 !important;
  border-left: 4px solid #7c0000 !important;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
  float: none !important;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
  float: none !important;
}

table th{
  margin-top: 10px;
  margin-bottom: 10px;
}

.table-container table tr{
  padding-left: 16px;
}